import axios, { AxiosResponse } from "axios";
import URL from "url-parse";
import { Config } from "../../config";
import { OrderDialogPayload } from "components/dialogs/OrderDialog/OrderDialogFormValues";

export const sendOrderMailAsync = async (
  payload: OrderDialogPayload,
  config: Config,
  bearerToken: string | undefined
): Promise<AxiosResponse<any>> => {
  const url = new URL("api/v1/mail/order", config.apiServerUri);
  return await axios.post(url.href, payload, {
    headers: {
      Authorization: `Bearer ${bearerToken ?? ""}`
    }
  });
};
